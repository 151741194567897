import { Link, Outlet } from "react-router-dom"
import './navigation.styles.scss'
import logo from '../../assets/avtub-high-resolution-logo-transparent.png'
import { useState,useEffect } from "react"
import SearchBar from "../../component/searchbar/searchbar.component"

const NavBar = () => {
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleScroll = () => {
    if (window.scrollY > lastScrollY) {
      // Scroll ke bawah, sembunyikan navbar kedua
      setIsNavbarVisible(false);
    } else {
      // Scroll ke atas, tampilkan navbar kedua
      setIsNavbarVisible(true);
    }
    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

    return(
       <>
        <div className="h-[40px] w-[100%] center
         bg-black text-pink-500 font-bold 
         flex items-center justify-center mb-[90px] lg:sticky top-0 z-50 
           ">
         <div className="space-x-3 text-sm">
            <Link className="" to='bokep-indo/page/:page'>
               BOKEP INDO
            </Link>
            <Link className="" to='bokep-jepang/page/:page'>
                BOKEP JEPANG
            </Link>
            <Link className="" to='bokep-barat/page/:page'>
                BOKEP BARAT 
            </Link>
         </div>
         </div>
         <div className={`h-[40px] mt-[-90px] lg:mt-0 w-full bg-black text-pink-500
          flex items-center justify-center space-x-5 lg:fixed lg:top-[41px]
          left-0 transition-opacity duration-300 z-50 ${
          isNavbarVisible ? 'opacity-100' : 'opacity-0'
        }`}>
            <Link to='/' >
                <div className="w-14">
                  <img height='10' width='56' src={logo} alt="coy tube" className="w-[58px]" />
                </div>
            </Link>
            <div>
              
              <SearchBar />
            </div>
            
         </div>
         <Outlet />
       </>
    )
}

export default NavBar